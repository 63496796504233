import { useEffect, useState } from 'react';
import { withToast } from 'material-ui-toast-redux';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';

import { get, post } from 'helpers/apiHelpers';

import { fetchDiets } from 'actions/Diets';
import { fetchVariants } from 'actions/Variants';

import { paymentTypesTranslations } from '../columnConfig';

import DELIVERY_TYPES from './consts/deliveryTypes';
import { fetchMealTypes } from 'actions/MealTypes';

const restrictedPaymentMethods = ['PAYU_PAY_BY_LINKS'];

const NewOrderContainer = Component => {
  const WrappedComponent = ({ openToast, history, ...props }) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [brandInfo, setBrandInfo] = useState({});
    const selectedBrand = useSelector(state => state?.Auth.selectedBrand);
    const [isCopyPaymentLinkDialogOpened, setIsCopyPaymentLinkDialogOpened] =
      useState(false);
    const [orderCost, setOrderCost] = useState({});
    const [paymentLink, setPaymentLink] = useState(false);
    const [pickUpPoints, setPickUpPoints] = useState([]);
    const [deliveryTypes, setDeliveryTypes] = useState([]);
    const [availableAddons, setAvailableAddons] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState('');
    const [customerAddresses, setCustomerAddresses] = useState([]);
    const [isInvoiceIncluded, setIsInvoiceIncluded] = useState(false);
    const [customerInvoiceData, setCustomerInvoiceData] = useState({});
    const [isAllowedPremium, setIsAllowedPremium] = useState(false);
    const [isAllowedEcoContainer, setIsAllowedEcoContainer] = useState(false);
    const [isAllowedTestDays, setIsAllowedTestDays] = useState(false);
    const [useTestMode, setUseTestMode] = useState(false);

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [diets, setDiets] = useState([]);

    const [paymentType, selectPaymentType] = useState(null);
    const [paymentMethods, setPaymentMethods] = useState([]);

    // Promo code & Money box
    const [usePromoCode, setUsePromoCode] = useState(false);
    const [promoCode, setPromoCode] = useState(null);
    const [useMoneyBox, setUseMoneyBox] = useState(false);
    const [sendConfirmation, setSendConfirmation] = useState(true);

    const [calendarSettings, setCalendarSettings] = useState({
      disabledDays: [],
    });

    useEffect(() => {
      checkPrice();
    }, [
      diets,
      selectedCustomer,
      useMoneyBox,
      usePromoCode,
      promoCode,
      useTestMode,
    ]);

    useEffect(() => {
      if (typeof getQueryVariable('userId') !== 'undefined') {
        Promise.all([get(`clients/${getQueryVariable('userId')}`)]).then(
          ([user]) => {
            const selectedUser = {
              id: user?.id,
              label: `${user?.firstName} ${user?.lastName} (${user?.email})`,
              value: user?.['@id'],
              invoiceInfo: user?.invoiceCompany
                ? {
                    invoiceCompany: user?.invoiceCompany,
                    invoiceVatNumber: user?.invoiceVatNumber,
                    invoiceAddressCity: user?.invoiceAddressCity,
                    invoiceAddressStreet: user?.invoiceAddressStreet,
                    invoiceAddressPostCode: user?.invoiceAddressPostCode,
                    invoiceAddressBuildNumber: user?.invoiceAddressBuildNumber,
                    invoiceAddressPlaceNumber: user?.invoiceAddressPlaceNumber,
                  }
                : {},
            };
            selectCurrentUser(selectedUser);
          }
        );
      }

      Promise.all([
        dispatch(fetchDiets()),
        dispatch(fetchVariants()),
        get('/frontend/payment-types'),
        get('/pick-up-points'),
        get(`/brands/${selectedBrand}`),
        get('/addons', {
          itemsPerPage: 1000,
        }),
        dispatch(fetchMealTypes()),
      ]).then(
        ([
          diets,
          variants,
          paymentTypes,
          pickupsResponse,
          brandInfo,
          addons,
        ]) => {
          setBrandInfo(brandInfo);
          setDeliveryTypes([
            {
              ...(brandInfo?.deliveryToDoorEnabled
                ? {
                    value: DELIVERY_TYPES.ADDRESS,
                    label: t('brands.newBrandForm.toDoor', 'Dostawa pod drzwi'),
                  }
                : {}),
            },
            {
              ...(brandInfo?.pickUpPointEnabled
                ? {
                    value: DELIVERY_TYPES.PICK_UP_POINT,
                    label: t('orders.pickupInPerson', 'Odbiór osobisty'),
                  }
                : {}),
            },
          ]);

          setCalendarSettings({
            daysDefault: brandInfo?.daysDefault ?? 1,
            disabledDays: brandInfo?.disabledDays,
            saturdaySettings: brandInfo?.saturday,
            minDietLength: 1,
            maxDietLength: 365,
          });

          setPickUpPoints(
            (pickupsResponse?.['hydra:member'] ?? []).map(pickUpPoint => ({
              value: pickUpPoint?.['@id'],
              label: pickUpPoint.value,
            }))
          );

          setDiets([
            {
              dietLength: brandInfo?.daysDefault ?? 1,
              selectedDays: [],
              selectedAddons: [],
              selectedAdditionalMealTypes: [],
            },
          ]);

          setAvailableAddons(
            (addons?.['hydra:member'] ?? []).map(addon => ({
              value: addon?.['@id'],
              label: addon?.name,
              price: addon?.clientCost,
            }))
          );

          get(`brands/${selectedBrand}/`).then(response => {
            setIsAllowedEcoContainer(response.ecoContainer);
            setIsAllowedPremium(response.premiumType);
          });

          return get(
            `brands/${selectedBrand}/modules?module[]=ItemPaymentTypes&module[]=AdditionalPayment`
          ).then(response => {
            const adminAdditionalPayments =
              response?.configuration?.AdditionalPayment ?? {};
            const filteredAdminAdditionalPayments = Object.entries(
              adminAdditionalPayments
            )
              .filter(([key]) => !['@id', '@type'].includes(key))
              .reduce((acc, [key, value]) => {
                if (value) {
                  return [...acc, { type: key.toUpperCase() }];
                }

                return acc;
              }, []);

            const paymentMethods = [
              ...(paymentTypes?.['hydra:member'] ?? []),
              ...filteredAdminAdditionalPayments,
            ]
              .map(method => ({
                value: method.type,
                label: t(paymentTypesTranslations[method.type]),
              }))
              .filter(
                method => !restrictedPaymentMethods.includes(method.value)
              );

            setPaymentMethods(paymentMethods);

            const defaultPaymentTypeValue =
              response.configuration.ItemPaymentTypes.standard
                .defaultPaymentType;

            const defaultPaymentType = paymentMethods.find(
              method =>
                method.value === defaultPaymentTypeValue &&
                !restrictedPaymentMethods.includes(method.value)
            );

            if (!isEmpty(defaultPaymentType)) {
              selectPaymentType(defaultPaymentType);
            }
          });
        }
      );
    }, []);

    useEffect(() => {
      if (isEmpty(diets)) return;
      const currentDiet = diets[0] ?? {};
      updateDiet(
        {
          ...currentDiet,
          dietLength: useTestMode
            ? brandInfo?.testDaysDefault ?? 1
            : brandInfo?.daysDefault ?? 1,
          selectedDays: [],
          selectedAddons: [],
        },
        0
      );
      setCalendarSettings(prev => ({
        ...prev,
        minDietLength: useTestMode ? brandInfo?.testDaysMin ?? 1 : 1,
        maxDietLength: useTestMode ? brandInfo?.testDaysMax ?? 365 : 365,
      }));
    }, [useTestMode]);

    const getQueryVariable = variable => {
      var query = window.location.search.substring(1);
      var vars = query.split('&');
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) === variable) {
          return decodeURIComponent(pair[1]);
        }
      }
    };

    const selectCurrentUser = async user => {
      if (user === null) {
        setCustomerAddresses([]);
        return setSelectedCustomer(null);
      }

      get(`ecommerce-diets`, { 'client.id': user?.id }).then(response => {
        setUseTestMode(false);
        if (response['hydra:member'].length === 0) {
          setIsAllowedTestDays(true);
          return;
        }
        setIsAllowedTestDays(false);
      });
      try {
        const addresses = await get(`clients/${user?.id}/addresses`);

        setCustomerInvoiceData(user?.invoiceInfo);
        setCustomerAddresses(
          (addresses?.['hydra:member'] ?? []).map(address => ({
            ...address,
            value: address?.['@id'],
            label: `${address.street} ${address.buildNumber}${
              address.placeNumber ? '/' + address.placeNumber : ''
            }, ${address.postCode} ${address.city} ${
              address?.zone
                ? ''
                : `(${t('$*common.notSupported', '$$Nieobsługiwany')})`
            }`,
          }))
        );
        return setSelectedCustomer(user);
      } catch (e) {
        setCustomerAddresses([]);
        return setSelectedCustomer(null);
      }
    };

    const updateCustomerAddresses = async () => {
      const addresses = await get(`clients/${selectedCustomer?.id}/addresses`);
      setCustomerAddresses(
        (addresses?.['hydra:member'] ?? []).map(address => ({
          ...address,
          value: address?.['@id'],
          label: `${address.street} ${address.buildNumber}${
            address.placeNumber ? '/' + address.placeNumber : ''
          }, ${address.postCode} ${address.city}`,
        }))
      );
    };

    const promiseOptions = inputValue =>
      new Promise(resolve => {
        const splicedName = inputValue.split(' ');
        let filter = {};

        if (splicedName.length === 2) {
          filter = {
            firstName: splicedName[0],
            lastName: splicedName[1],
          };
        } else {
          filter = {
            lastName: splicedName,
            firstName: splicedName,
            email: splicedName,
          };
        }
        const operator = [2, 3].includes(splicedName.length) ? '_andX' : '_orX';

        const query = { [operator]: [[filter]] };
        get('clients', query).then(data => {
          resolve(
            data['hydra:member'].map(row => {
              return {
                id: row?.id,
                label: `${row.firstName} ${row.lastName} (${row.email})`,
                value: row['@id'],
                moneyBox: row.moneyBox,
                invoiceInfo: row.invoiceCompany
                  ? {
                      invoiceCompany: row.invoiceCompany,
                      invoiceVatNumber: row.invoiceVatNumber,
                      invoiceAddressPostCode: row.invoiceAddressPostCode,
                      invoiceAddressCity: row.invoiceAddressCity,
                      invoiceAddressStreet: row.invoiceAddressStreet,
                      invoiceAddressBuildNumber: row.invoiceAddressBuildNumber,
                      invoiceAddressPlaceNumber: row.invoiceAddressPlaceNumber,
                    }
                  : {},
              };
            })
          );
        });
      });

    const selectPromoCode = code => {
      if (code === null) {
        return setPromoCode(null);
      }

      const today = new Date(moment()).setHours(0, 0, 0, 0);
      let validFrom;
      let validTo;

      const codeError = error => {
        return openToast({
          messages: [error],
          type: 'warning',
          autoHideDuration: 3000,
        });
      };
      const errorMessage_inactive = t('orders.thisDiscountCode');
      const errorMessage_expired = t('orders.discountCodeExpired');

      if (code.value.validFrom && !code.value.validTo) {
        validFrom = new Date(moment(code.value.validFrom)).setHours(0, 0, 0, 0);
        validTo = null;

        validFrom > today
          ? codeError(errorMessage_inactive)
          : setPromoCode(code);
      } else if (!code.value.validFrom && code.value.validTo) {
        validFrom = null;
        validTo = new Date(moment(code.value.validTo)).setHours(0, 0, 0, 0);

        validTo < today ? codeError(errorMessage_expired) : setPromoCode(code);
      } else if (code.value.validFrom && code.value.validTo) {
        validFrom = new Date(moment(code.value.validFrom)).setHours(0, 0, 0, 0);
        validTo = new Date(moment(code.value.validTo)).setHours(0, 0, 0, 0);

        validTo < today
          ? codeError(errorMessage_expired)
          : validFrom > today
          ? codeError(errorMessage_inactive)
          : setPromoCode(code);
      } else {
        return setPromoCode(code);
      }
    };

    const promisePromoCodes = inputValue =>
      new Promise(resolve => {
        const query = { code: inputValue, active: true };
        get('discount-codes/all', query).then(data => {
          resolve(
            data['hydra:member'].map(code => {
              const codeLabel = `${code.code}, rabat ${
                code.isPercentage ? code.discount + '%' : code.discount + ' zł'
              }`;
              return {
                label: codeLabel,
                value: code,
              };
            })
          );
        });
      });

    const updateDiet = (updatedDiet, updatedDietIndex) => {
      if (updatedDiet?.selectedVariant?.superVariantBeta) {
        if (
          updatedDiet?.selectedSuperVariant?.length <
            updatedDiet?.selectedVariant?.minMealTypesVariant &&
          updatedDiet?.selectedVariant?.value ===
            diets?.[updatedDietIndex]?.selectedVariant?.value
        ) {
          return openToast({
            messages: [
              t(
                'orders.selectedMinMealTypes.supervariantBeta.warnings',
                'Nie można usunąć typu posiłku, ponieważ nie zostanie osiągnięte minimum logistyczne'
              ),
            ],
            type: 'warning',
            autoHideDuration: 3000,
          });
        }
      }

      const updatedDiets = diets.map((diet, index) =>
        index === updatedDietIndex ? updatedDiet : diet
      );
      setDiets(updatedDiets);
    };

    const checkPrice = () => {
      const postData = {
        client: selectedCustomer?.value,
        paymentType: paymentType?.value,
        useMoneyBox,
        sendConfirmation,
        discountCode: usePromoCode && promoCode ? promoCode.value['@id'] : null,
        testMode: useTestMode,
        diets: diets
          .filter(diet => validateDiet(diet))
          .map(diet => {
            const {
              address,
              dietLength,
              pickUpPoint,
              selectedDays,
              selectedDiet,
              selectedAddons,
              selectedVariant,
              useEcoContainers,
              optionChangeMenu,
              selectedCalorific,
              sundayInclude,
              saturdayInclude,
              selectedSuperVariant,
              customDietName,
            } = diet ?? {};

            const additionalMealTypesArray = [];

            for (
              let i = 0;
              i < diet?.selectedAdditionalMealTypes?.length;
              i++
            ) {
              const mealType = diet?.selectedAdditionalMealTypes[i]?.value;
              const dateWithAmount =
                diet?.selectedAdditionalMealTypes[i]?.dateWithAmount;

              for (let j = 0; j < dateWithAmount?.length; j++) {
                const day = dateWithAmount[j]?.date.format('YYYY-MM-DD');
                const quantity = dateWithAmount[j]?.amount;

                const mealObject = {
                  mealType,
                  day,
                  quantity,
                };
                additionalMealTypesArray.push(mealObject);
              }
            }

            return {
              name: customDietName ?? '',
              additionalMealTypes: additionalMealTypesArray,
              addons: (selectedAddons ?? []).map(({ value, amount, type }) => ({
                addon: { '@id': value },
                quantity: amount,
                type,
              })),
              calorific: selectedCalorific?.value,
              deliveryDates: (selectedDays ?? [moment(new Date())]).map(
                momentDate => momentDate.format('YYYY-MM-DD')
              ),
              diet: selectedDiet?.value,
              delivery: {
                pickUpPoint: pickUpPoint?.value ?? null,
                address: address?.['@id'] ?? null,
              },
              dietLength: dietLength || 1,
              firstDeliveryDay: selectedDays[0].format('YYYY-MM-DD'),
              optionChangeMenu: optionChangeMenu ?? false,
              package: null,
              paymentMode: 'SINGLE_PAYMENT',
              useEcoContainers: useEcoContainers ?? false,
              variant: selectedVariant?.value,
              sundayInclude: sundayInclude ?? false,
              saturdayInclude: saturdayInclude ?? false,
              mealTypes: selectedSuperVariant
                ? selectedSuperVariant?.map(el => el.value)
                : [],
            };
          }),
      };

      if (!isEmpty(postData?.diets)) {
        post('/v2/orders', postData).then(
          res => {
            setOrderCost(res);
          },
          error => {
            openToast({
              messages: [
                t('orders.cannotPriceOrder'),
                t('orders.priceListSet'),
              ],
              type: 'error',
              autoHideDuration: 5000,
            });
          }
        );
      }
    };

    const addDiet = () => {
      setDiets([
        ...diets,
        {
          dietLength: calendarSettings?.daysDefault ?? 1,
          selectedDays: [],
          selectedAddons: [],
        },
      ]);
    };

    const validateDiet = diet => {
      const {
        address,
        dietLength,
        pickUpPoint,
        selectedDays,
        selectedDiet,
        selectedVariant,
        selectedCalorific,
      } = diet ?? {};

      return (
        !isEmpty(selectedDiet) &&
        !isEmpty(selectedVariant) &&
        !isEmpty(selectedCalorific) &&
        dietLength === selectedDays?.length &&
        selectedDays.length > 0 &&
        (!isEmpty(address) || !isEmpty(pickUpPoint))
      );
    };

    const getDietCost = dietIndex => {
      const isDietValid = validateDiet(diets[dietIndex]);

      if (!isDietValid) return null;

      return orderCost.basket;
    };

    const removeDiet = indexToRemove => {
      setDiets(diets.filter((diet, index) => index !== indexToRemove));
    };

    const validateForm = () => {
      //Make sure that amount of selected days is same as declared diet length
      const dietValidate = diets
        .map(diet => {
          return diet.dietLength === diet.selectedDays.length;
        })
        .every(el => el === true);

      if (!dietValidate) {
        openToast({
          messages: [
            t(
              'orders.makeSureThatAmountOfSelectedDaysIsSameAsDeclaredDietLength',
              '$$Upewnij się, że we wszystkich dietach liczba wybranych dni jest taka sama jak zadeklarowana długość diety'
            ),
          ],
          type: 'error',
          autoHideDuration: 3000,
        });
        return false;
      }

      const validationArray = diets.map(
        diet =>
          (diet.address ?? !!diet.pickUpPoint) &&
          diet.dietLength === diet.selectedDays.length &&
          !!diet.selectedDays &&
          !!diet.selectedDiet &&
          !!diet.selectedAddons &&
          !!diet.selectedVariant &&
          !!diet.selectedCalorific
      );
      const areAllDietsValid = validationArray.every(el => el === true);

      // Check if all added diets are valid
      if (!areAllDietsValid) {
        openToast({
          messages: [
            t(
              'orders.fillAllRequiredFields',
              '$$Uzupełnij wymagane pola we wszystkich dodanych dietach'
            ),
          ],
          type: 'error',
          autoHideDuration: 3000,
        });
        return false;
      }

      // Check if payment method is selected
      if (!paymentType) {
        openToast({
          messages: [t('', '$$Wybierz metodę płatności')],
          type: 'error',
          autoHideDuration: 3000,
        });
        return false;
      }

      return true;
    };

    const handleSubmit = () => {
      setIsSubmitting(true);
      const isValid = validateForm();

      if (!isValid) return setIsSubmitting(false);

      const invoicesData = {
        address: {
          buildNumber: customerInvoiceData.invoiceAddressBuildNumber,
          city: customerInvoiceData.invoiceAddressCity,
          placeNumber: customerInvoiceData.invoiceAddressPlaceNumber,
          postCode: customerInvoiceData.invoiceAddressPostCode,
          street: customerInvoiceData.invoiceAddressStreet,
        },
        companyName: customerInvoiceData.invoiceCompany,
        include: isInvoiceIncluded,
        type: 'Other',
        vatNumber: customerInvoiceData.invoiceVatNumber,
      };

      const postData = {
        client: selectedCustomer?.value,
        paymentType: paymentType?.value,
        isInvoiceIncluded: isInvoiceIncluded,
        useMoneyBox,
        sendConfirmation,
        discountCode: usePromoCode && promoCode ? promoCode.value['@id'] : null,
        testMode: useTestMode,
        transformToOrder: {
          ...(isInvoiceIncluded && { invoice: invoicesData }),
        },
        diets: diets
          .filter(diet => validateDiet(diet))
          .map(diet => {
            const {
              address,
              dietLength,
              pickUpPoint,
              selectedDays,
              selectedDiet,
              selectedAddons,
              selectedVariant,
              useEcoContainers,
              optionChangeMenu,
              selectedCalorific,
              sundayInclude,
              saturdayInclude,
              selectedSuperVariant,
              customDietName,
            } = diet ?? {};

            const additionalMealTypesArray = [];

            for (let i = 0; i < diet.selectedAdditionalMealTypes.length; i++) {
              const mealType = diet.selectedAdditionalMealTypes[i].value;
              const dateWithAmount =
                diet.selectedAdditionalMealTypes[i].dateWithAmount;

              for (let j = 0; j < dateWithAmount.length; j++) {
                const day = dateWithAmount[j].date.format('YYYY-MM-DD');
                const quantity = dateWithAmount[j].amount;

                const mealObject = {
                  mealType,
                  day,
                  quantity,
                };
                additionalMealTypesArray.push(mealObject);
              }
            }

            return {
              name: customDietName ?? '',
              additionalMealTypes: additionalMealTypesArray,
              addons: (selectedAddons ?? []).map(({ value, amount, type }) => ({
                addon: { '@id': value },
                quantity: amount,
                type,
              })),
              calorific: selectedCalorific?.value,
              deliveryDates: (selectedDays ?? [moment(new Date())]).map(
                momentDate => momentDate.format('YYYY-MM-DD')
              ),
              diet: selectedDiet?.value,
              delivery: {
                pickUpPoint: pickUpPoint?.value ?? null,
                address: address?.['@id'] ?? null,
              },
              dietLength: dietLength || 1,
              firstDeliveryDay: selectedDays[0].format('YYYY-MM-DD'),
              optionChangeMenu: optionChangeMenu ?? false,
              package: null,
              paymentMode: 'SINGLE_PAYMENT',
              useEcoContainers: useEcoContainers ?? false,
              variant: selectedVariant?.value,
              sundayInclude: sundayInclude ?? false,
              saturdayInclude: saturdayInclude ?? false,
              mealTypes: selectedSuperVariant
                ? selectedSuperVariant?.map(el => el.value)
                : [],
            };
          }),
      };

      post('/v2/orders', postData).then(
        el => {
          if (
            el?.basket?.paymentType === 'PAYU' ||
            el?.basket?.paymentType === 'BLUE_MEDIA' ||
            el?.basket?.paymentType === 'PAYNOW'
          ) {
            setPaymentLink(el.basket?.placedOrder?.paymentLink);
            setIsCopyPaymentLinkDialogOpened(true);
          } else {
            history.push('/admin/orders');
            return openToast({
              messages: [
                t(
                  'bagChangeModal.orderCreated',
                  '$$Zostało stworzone nowe zamówienie'
                ),
              ],
              type: 'success',
              autoHideDuration: 5000,
            });
          }
        },
        error => {
          setIsSubmitting(false);
          if (error?.response?.data?.violations?.[0]) {
            return openToast({
              messages: [error?.response?.data?.violations?.[0].message],
              type: 'error',
              autoHideDuration: 5000,
            });
          } else {
            return openToast({
              messages: [t('orders.smthWentWrong')],
              type: 'error',
              autoHideDuration: 5000,
            });
          }
        }
      );
    };

    return (
      <Component
        {...props}
        diets={diets}
        history={history}
        addDiet={addDiet}
        orderCost={orderCost}
        promoCode={promoCode}
        removeDiet={removeDiet}
        updateDiet={updateDiet}
        paymentLink={paymentLink}
        paymentType={paymentType}
        useMoneyBox={useMoneyBox}
        getDietCost={getDietCost}
        handleSubmit={handleSubmit}
        isSubmitting={isSubmitting}
        pickUpPoints={pickUpPoints}
        usePromoCode={usePromoCode}
        deliveryTypes={deliveryTypes}
        paymentMethods={paymentMethods}
        setUseMoneyBox={setUseMoneyBox}
        promiseOptions={promiseOptions}
        availableAddons={availableAddons}
        setUsePromoCode={setUsePromoCode}
        selectPromoCode={selectPromoCode}
        setIsSubmitting={setIsSubmitting}
        calendarSettings={calendarSettings}
        sendConfirmation={sendConfirmation}
        selectedCustomer={selectedCustomer}
        customerAddresses={customerAddresses}
        promisePromoCodes={promisePromoCodes}
        selectCurrentUser={selectCurrentUser}
        selectPaymentType={selectPaymentType}
        isInvoiceIncluded={isInvoiceIncluded}
        setSendConfirmation={setSendConfirmation}
        customerInvoiceData={customerInvoiceData}
        setIsInvoiceIncluded={setIsInvoiceIncluded}
        setCustomerInvoiceData={setCustomerInvoiceData}
        updateCustomerAddresses={updateCustomerAddresses}
        isCopyPaymentLinkDialogOpened={isCopyPaymentLinkDialogOpened}
        setIsCopyPaymentLinkDialogOpened={setIsCopyPaymentLinkDialogOpened}
        isAllowedEcoContainer={isAllowedEcoContainer}
        isAllowedPremium={isAllowedPremium}
        isMoreThanOneDiet={diets?.length > 1}
        isAllowedTestDays={isAllowedTestDays}
        useTestMode={useTestMode}
        setUseTestMode={setUseTestMode}
        brandInfo={brandInfo}
      />
    );
  };

  return withToast(WrappedComponent);
};

export default NewOrderContainer;

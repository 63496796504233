import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter } from 'react-router-dom';
import types from 'helpers/constants';
// actions import
import {
  handleBrandChange,
  reloadTranslations,
  logout,
  refreshLogin,
} from 'actions/Auth';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// core components
import AdminNavbar from 'components/Navbars/AdminNavbar.jsx';
import Sidebar from 'components/Sidebar/Sidebar.jsx';
import qs, { parse } from 'query-string';
import routes from '../routes.js';

import appStyle from 'assets/jss/material-dashboard-pro-react/layouts/adminStyle.jsx';
import { Toast } from 'material-ui-toast-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx';
import { get } from 'helpers/apiHelpers';
import logo from 'assets/img/logo-white.svg';
import { connect } from 'react-redux';
import { store } from '../store';
import AppDialogLoader from '../components/DialogLoader/AppDialogLoader';
import styled from 'styled-components';
import { createHotjar } from 'utils/hotjar';
import { createZohoSalesIQ } from 'utils/zohoSalesIQ';
import { fetchCountryInformations } from 'actions/Brands';
import { getCompany } from 'actions/Company';
import { setRegionForValidations } from 'actions/I18nSection';
import { withTranslation } from 'react-i18next';
import { combineStyles } from '../helpers/helpers';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import { compose } from 'redux';
import SecuredView from 'components/Security/SecuredView';

// var ps;

// define paths in which global app dialog loader should not be active
const excludedPathsForAppDialog = [
  '/orders/add',
  '/meals/edit',
  '/meals/add',
  '/recipes/edit',
  '/recipes/add',
  '/prices',
  '/delivery',
  'admin/diets',
  '/admin/clients/edit/:id/address/edit/:id',
  '/admin/clients/edit/:id/address/add',
  '/admin/employees/edit/:id',
  '/admin/products/edit',
];

const StyledToast = styled(Toast)`
  [class*='MuiSnackbarContent-message-'] {
    width: calc(100% - 60px);
  }
`;

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rolesLoaded: false,
      logo: null,
      pathsWithDisabledLoader: [],
      locationPath: null,
      miniActive: false,
    };
    this.resizeFunction = this.resizeFunction.bind(this);
  }

  uploadedContentBaseURL =
    process.env.REACT_APP_API_ENDPOINT + '/uploaded-media/';

  async componentDidMount() {
    await this.props.refreshLogin();
    this.setState({
      locationPath: window.location.pathname,
    });
    store.dispatch({
      type: types.APP_FETCH_RESET,
    });

    const selectedBrand = this.props.brands.find(
      brand => brand.id === this.props.brand
    );
    if (selectedBrand) {
      get(selectedBrand['@id']).then(async res => {
        store.dispatch({
          type: types.FETCH_BRAND,
          payload: res,
        });

        // TODO: inconsistency - why - company may have different types on dev and on stage
        this.props
          .getCompany(
            typeof res['company'] === 'object'
              ? res['company']['@id']
              : res['company']
          )
          .then(({ hotJar: { id, version, enabled }, zohoSalesIQ }) => {
            if (enabled) {
              createHotjar(id, version);
            }
            if (zohoSalesIQ?.enabled && zohoSalesIQ?.widgetCode) {
              createZohoSalesIQ(zohoSalesIQ?.widgetCode);
            }
          });

        await this.props.fetchCountryInformations();
        const supportedCountry = res.multinational.supportedRegions?.[0];
        const defaultSelectedRegionForValidation =
          this.props.countryInformations?.find(
            ({ code }) => supportedCountry === code
          );
        this.props.setRegionForValidations(defaultSelectedRegionForValidation);
        //
        // const getCountryForValidation = countryCode =>
        //   countries?.find(({ code }) => code === countryCode);
        await this.props.reloadTranslations();

        this.setState(prev => ({
          ...prev,
          logo: res.logo,
          brandShortName: res.shortName,
          brandName: res.name,
          rolesLoaded: true,
        }));
      });
    } else if (this.props.brands.length === 0) {
      this.props.logout();
      this.props.history.push('/auth/login');
    }

    const brandFromQuery = parse(this.props.history.location.search)[
      'selectedBrand'
    ];
    const brandToSelect = this.props.brands.find(
      brand => brand.id === +brandFromQuery
    );

    brandToSelect &&
      this.props.handleBrandChange(null, { brand: brandToSelect });
  }

  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.setState({
        locationPath: window.location.pathname,
      });
      if (this.state.mobileOpen) {
        this.setState({
          mobileOpen: false,
          locationPath: window.location.pathname,
        });
      }
    }
    this.setSelectedBrand();
  }

  setSelectedBrand = () => {
    const queryParams = qs.parse(this.props.history.location.search);
    const newQueries = { ...queryParams, selectedBrand: this.props.brand };

    if (this.props.history.location.search !== newQueries) {
      window.history.replaceState(
        null,
        null,
        window.location.pathname + '?' + qs.stringify(newQueries)
      );
    }
  };

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  getRoute() {
    return this.props.location.pathname !== '/admin/full-screen-maps';
  }

  getActiveRoute = routes => {
    let activeRoute = '';
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = this.getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return this.props.t(
            routes[i].name,
            routes[i]?.nameFallback ?? routes[i]?.name
          );
        }
      }
    }
    return activeRoute;
  };
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === '/admin') {
        return (
          <Route
            path={prop.layout + prop.path}
            render={props => {
              const Component = (
                <prop.component {...props} {...prop.componentProps} />
              );

              if (prop.role) {
                return (
                  <SecuredView role={prop.role} returnNull={false}>
                    {Component}
                  </SecuredView>
                );
              }

              return Component;
            }}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  setDocTitle = () => {
    let docTitle = `${
      this.state.brandShortName
        ? this.state.brandShortName
        : this.state.brandName
        ? this.state.brandName
        : ''
    } | ${this.getActiveRoute(routes)}`;

    const isEdit = window.location.pathname.includes('edit');
    const isAdd =
      window.location.pathname.includes('add') &&
      !window.location.pathname.includes('address');
    const isPreview = window.location.pathname.includes('preview');

    if (isEdit) {
      docTitle += ' > Edycja'; //common.edit
    } else if (isAdd) {
      docTitle += ' > Tworzenie'; //common.add
    } else if (isPreview) {
      docTitle += ' > Podgląd'; //common.preview
    }
    const stringNumbers = window.location.pathname.match(/\d+/g);

    if (stringNumbers && (isEdit || isAdd || isPreview)) {
      const joinedString = stringNumbers.join('-');

      docTitle += ` > ${joinedString}`;
    }

    if (this.state.brandName) {
      document.title = docTitle;
    }
  };

  sidebarMinimize() {
    this.setState({ miniActive: !this.state.miniActive });
  }

  resizeFunction() {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  }

  render() {
    this.setDocTitle();

    const { classes, t, ...rest } = this.props;

    if (!this.state.rolesLoaded) {
      return null;
    }

    const selectedBrand = this.props.brands.find(
      brand => brand.id === this.props.brand
    );

    return (
      <div className={classes.wrapper}>
        <AppDialogLoader
          text={t('common.loader', 'Trwa ładowanie...')} // common.loader
          excludedPaths={excludedPathsForAppDialog}
        />
        <Sidebar
          routes={routes}
          logoText={selectedBrand ? selectedBrand.name : 'Gastro'}
          logo={logo}
          brandLogo={
            this.state.logo
              ? `${this.uploadedContentBaseURL}${this.state.logo.contentUrl}`
              : ''
          }
          handleDrawerToggle={this.handleDrawerToggle}
          open={this.state.mobileOpen}
          miniActive={this.state.miniActive}
          minimizeSidebar={() => {
            this.sidebarMinimize.bind(this)();
          }}
          {...rest}
        />
        <div
          className={classNames(classes.mainPanel, {
            [classes.mainPanelSidebarMini]: this.state.miniActive,
          })}
          ref="mainPanel"
        >
          <AdminNavbar
            location={this.state.locationPath}
            brandText={this.getActiveRoute(routes)}
            handleDrawerToggle={this.handleDrawerToggle}
            {...rest}
          />
          {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
          {this.getRoute() ? (
            <div className={classes.content}>
              <div className={classes.container}>
                <Switch>{this.getRoutes(routes)}</Switch>
              </div>
            </div>
          ) : (
            <div className={classes.map}>
              <Switch>{this.getRoutes(routes)}</Switch>
            </div>
          )}
        </div>
        <StyledToast />
        {this.props.sweetAlerts
          .filter(
            el =>
              typeof el.content === 'undefined' ||
              React.isValidElement(el.content)
          )
          .map((sweetAlert, i) => (
            <SweetAlert
              key={i}
              confirmBtnCssClass={`${classes.button} ${classes.success}`}
              cancelBtnCssClass={`${classes.button} ${classes.danger}`}
              {...sweetAlert}
            >
              {sweetAlert.content || ''}
            </SweetAlert>
          ))}
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

const combinedStyles = combineStyles(
  buttonsStyle,
  extendedFormsStyle,
  sweetAlertStyle
);

const mapStateToProps = state => {
  return {
    brands: state.Auth.user.brands,
    brand: state.Auth.selectedBrand,
    countryInformations: state.Brands.countryInformations,
    sweetAlerts: state.App.sweetAlerts,
  };
};

const mapDispatchToProps = {
  handleBrandChange,
  refreshLogin,
  fetchCountryInformations,
  setRegionForValidations,
  logout,
  getCompany,
  reloadTranslations,
};

const enhance = compose(
  withTranslation(),
  withStyles(combinedStyles),
  withRouter,
  withStyles(appStyle),
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(Dashboard);
